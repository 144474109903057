import React from "react";
import SpacingProps from "../types/SpacingProps";

const SpacingVertical = (props: SpacingProps) => {
  const { className, rems } = props;
  const unwrappedRems = rems || 1.0;
  const computedHeight = {
    height: `${unwrappedRems}rem`,
  };
  return <div className={className} style={computedHeight} />;
};

export default SpacingVertical;
