import React from "react";
import Colors from "../constants/Colors";

const Input = ({
  className,
  placeholder,
  type,
  name,
  onChange,
  backgroundColor,
}) => {
  const computedStyle = { ...styles.input, backgroundColor };
  return (
    <input
      className={className}
      style={computedStyle}
      placeholder={placeholder}
      type={type}
      name={name}
      onChange={onChange}
    />
  );
};

const styles = {
  input: {
    display: "flex",
    flex: 1,
    borderWidth: "0px",
    padding: "0.75rem",
    textAlign: "left",
    fontSize: "0.875rem",
    color: Colors.blueDark,
    borderRadius: "0px",
  },
};

export default Input;
