import React from "react";
import Colors from "../constants/Colors";

const Banner = ({ children }) => {
  return <div style={styles.container}>{children}</div>;
};

const styles = {
  container: {
    backgroundColor: Colors.grayLight,
    padding: "1rem",
    marginBottom: "0px",
  },
};

export default Banner;
