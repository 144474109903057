import React from "react";
import HttpMethod from "../constants/HttpMethod";

export const Form = ({ className, name, children, style }) => (
  <form
    className={className}
    name={name}
    action=""
    method={HttpMethod.post}
    data-netlify="true"
    style={style}
  >
    {children}
  </form>
);

export default Form;
