import React, { useState } from "react";
import Button from "./Button";
import Form from "./Form";
import Banner from "./Banner";
import Input from "./Input";
import { isEmail } from "../lib/EmailValidator";
import Strings from "../constants/Strings";
import FormName from "../constants/FormName";
import HttpMethod from "../constants/HttpMethod";
import "../components/Layout.css";
import "../components/Shadow.css";

const getFilledOutElements = (event) => {
  const formElements = [...event.currentTarget.elements];
  const isValid =
    formElements.filter((elem) => elem.name === "bot-field")[0].value === "";

  const validFormElements = isValid ? formElements : [];

  const filledOutElements = validFormElements
    .filter((elem) => !!elem.value)
    .map(
      (element) =>
        encodeURIComponent(element.name) +
        "=" +
        encodeURIComponent(element.value)
    )
    .join("&");

  return filledOutElements;
};

const FormEmail = ({
  labelColor,
  inputColor,
  buttonTitle,
  formName = FormName.general,
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [submitText, setSubmitText] = useState(null);
  const [inputText, setInputText] = useState("");

  const onSubmit = async (event, setSubmitText) => {
    event.preventDefault();
    const body = getFilledOutElements(event);

    setSubmitted(true);
    setSubmitText(Strings.FormEmail.submitting);

    await fetch("/", {
      method: HttpMethod.post,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body,
    })
      .then(() => {
        setSubmitText(Strings.FormEmail.submitted);
      })
      .catch((_) => {
        setSubmitText(Strings.FormEmail.error);
      });
  };
  const renderForm = (labelColor, inputColor) => {
    if (submitted) return <Banner>{submitText}</Banner>;
    const labelComputedStyle = {
      ...styles.inputTitle,
      color: labelColor,
    };
    const disabled = isEmail(inputText) ? false : true;
    return (
      <Form name={formName} onSubmit={(e) => onSubmit(e, setSubmitText)}>
        <label
          style={labelComputedStyle}
        >{`${Strings.FormEmail.label} `}</label>
        <div style={styles.inputContainer}>
          <input type="hidden" name="form-name" value={formName} />
          <Input
            className="shadow"
            placeholder={Strings.FormEmail.placeholder}
            type="email"
            name="email"
            backgroundColor={inputColor}
            onChange={(e) => {
              setInputText(e.target.value);
            }}
          />
          <Button className="shadow" disabled={disabled}>
            {buttonTitle}
          </Button>
        </div>
      </Form>
    );
  };
  return renderForm(labelColor, inputColor);
};

const styles = {
  inputTitle: {
    fontSize: "0.75rem",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
  },
};

export default FormEmail;
