import React, { ReactChildren } from "react";
import Colors from "../constants/Colors";
import "./Link.css";

type ButtonProps = {
  className: string;
  children: JSX.Element | JSX.Element[] | null;
  disabled: boolean;
};

const Button = ({ className, children, disabled }: ButtonProps) => {
  return (
    <button
      className={`link ${className}`}
      disabled={disabled}
      style={styles.button}
    >
      {children}
    </button>
  );
};

const styles = {
  button: {
    backgroundColor: Colors.primary,
    borderWidth: "0px",
    color: Colors.white,
    fontSize: "0.8rem",
    fontWeight: 600,
    padding: "0.75rem",
  },
};

export default Button;
